var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SelectOrderTypeContainer',{scopedSlots:_vm._u([{key:"select-component",fn:function(ref){
    var ref_computeds = ref.computeds;
    var getOrderType = ref_computeds.getOrderType;
    var showScanQrMessage = ref_computeds.showScanQrMessage;
    var ref_events = ref.events;
    var handlerSelectOrderType = ref_events.handlerSelectOrderType;
    var handlerCheckMenu = ref_events.handlerCheckMenu;
return [_c('SelectOrderTypeComponent',{attrs:{"defaultOrderType":getOrderType,"handlerCheckMenu":handlerCheckMenu,"handlerSelectOrderType":handlerSelectOrderType}}),_c('v-alert',{staticClass:"my-0",attrs:{"text":"","value":showScanQrMessage,"type":"info","dense":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("scanQrIsRequiredForOrderType"))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }