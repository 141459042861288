<template>
  <SelectOrderTypeContainer>
    <template
      #select-component="{
        computeds: { getOrderType, showScanQrMessage },
        events: { handlerSelectOrderType, handlerCheckMenu },
      }"
    >
      <SelectOrderTypeComponent
        :defaultOrderType="getOrderType"
        :handlerCheckMenu="handlerCheckMenu"
        :handlerSelectOrderType="handlerSelectOrderType"
      />
      <v-alert
        text
        :value="showScanQrMessage"
        type="info"
        dense
        border="left"
        class="my-0"
      >
        {{ $t("scanQrIsRequiredForOrderType") }}
      </v-alert>
    </template>
  </SelectOrderTypeContainer>
</template>
<script>
export default {
  name: "SelectOrderType",
  components: {
    SelectOrderTypeComponent: () => import("./SelectOrderTypeComponent"),
    SelectOrderTypeContainer: () => import("../SelectOrderTypeContainer"),
  },
};
</script>
